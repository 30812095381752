import './FormSubmitButton.less'

import { Button } from 'antd'
import React from 'react'

import ArrowRight from '../../../assets/images/arrow-right.svg'

interface Props {
  label: string
  className?: string
  onClick?: () => Promise<void>
  loading?: boolean
}

export const FormSubmitButton: React.FC<Props> = ({ label, className, onClick, loading = false }) => (
  <Button
    className={`auth-form-submit-button ${loading ? 'auth-form-submit-button--loading' : ''} ${className || ''}`}
    type="primary"
    htmlType="submit"
    onClick={onClick}
    loading={loading}
  >
    {loading ? null : (
      <>
        <span>{label}</span>
        <ArrowRight className="auth-form-submit-button__arrow" />
      </>
    )}
  </Button>
)
